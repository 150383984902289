import React from 'react';
import { Button, FormInstance, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { usePrequal } from '../../../stores/prequalStore';
import { stepFinished } from '../../../prequalNav';
import { pushToDataLayer, getUrlCurrentStep } from '../../../utils';

import useHistory from '../../../hooks/useHistory';
import styles from './NavigationRow.module.scss';
import classNames from 'classnames';
import { isProduction } from '../../../constants';

type Props = {
  isFirstStep?: Boolean;
  isLastStep?: Boolean;
  form?: FormInstance;
  canContinue?: Boolean;
};

export const NavigationRow: React.FC<Props> = observer(function NavigationRow({
  isFirstStep = false,
  isLastStep = false,
  form,
  canContinue = false,
}) {
  const prequalStore = usePrequal();
  const history = useHistory();

  const handleBackClick = () => {
    const currentStep = getUrlCurrentStep(history.location.pathname) || 'unknown';
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: `click back on ${currentStep} page`,
    });

    if (isFirstStep) {
      const { prequal } = prequalStore;
      if (prequal?.imtId === 'NONBDP') {
        history.push('/pre-qualify/enter/');
      } else {
        const isProd = isProduction();
        window.open(
          `https://www.${!isProd ? 'qa.' : ''}boattrader.com/boat/${prequal.imtId}`,
          '_self'
        );
      }
    } else {
      prequalStore.navDirection = -1;
      stepFinished();
    }
  };

  const handleForwardClick = () => {
    prequalStore.navDirection = 1;
    if (form) {
      form.validateFields();
      if (isLastStep) {
        form.submit();
      }
    }
  };

  return (
    <div className={styles.navigationRow}>
      <div className={styles.navigationCol}>
        <div>
          <button type="button" className={styles.backLink} onClick={handleBackClick}>
            Back
          </button>
        </div>
        <div>
          <Button
            className={classNames(styles.forwardButton, {
              apply_now_btn: isLastStep,
              prequalButtonEnabled: canContinue,
            })}
            name="continue"
            type="primary"
            htmlType="submit"
            disabled={isLastStep && !canContinue}
            onClick={handleForwardClick}
          >
            <Space>
              {isLastStep ? 'Apply Now' : 'Continue'}
              <img src="/static/trident/arrow-circle-right.svg" alt="" />
            </Space>
          </Button>
        </div>
      </div>
    </div>
  );
});
