import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { usePrequal } from '../../stores/prequalStore';
import { pushToDataLayer } from '../../utils';
import { betterParseFloat } from '../../utils/betterParseFloat';
import PreQualFunnelLayout from '../PreQualFunnelLayout';
import { NavigationRow } from '../components/NavigationRow/NavigationRow';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import MoneyInput from '../components/MoneyInput/MoneyInput';
import { stepFinished } from '../../prequalNav';
import { Prequal } from '../../schema';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const MIN_PAYMENT = 79;
const MAX_PAYMENT = 100000;

const BoatPriceStep = observer(() => {
  const prequalStore = usePrequal();
  const { prequal } = prequalStore;
  const [canContinue, setCanContinue] = useState(false);
  const [form] = useForm();

  const updateValues = (values: any) => {
    prequalStore.setMonthlyPayment(values);
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'Boat Monthly Payment - Continue',
    });
    stepFinished();
  };

  const checkMonthlyPayment = () => {
    const { prequalApprovalMonthlyPayment } = form.getFieldsValue();
    const parsedMonthlyPayment = betterParseFloat(prequalApprovalMonthlyPayment) ?? 0;
    setCanContinue(parsedMonthlyPayment >= MIN_PAYMENT && parsedMonthlyPayment <= MAX_PAYMENT);
  };

  const validator = (_: any, value: any) => {
    const monthlyPayment = betterParseFloat(value) ?? NaN;
    if (Number.isNaN(monthlyPayment)) {
      return Promise.reject(new Error('This field is required'));
    }
    if (monthlyPayment < MIN_PAYMENT || monthlyPayment > MAX_PAYMENT) {
      return Promise.reject(new Error('Monthly payment must be between $79 and $100,000'));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'monthly payment',
      site_section: 'loan application',
    });
  }, []);

  useEffect(() => {
    const values = prequal as Prequal;
    if (values.prequalApprovalMonthlyPayment) {
      setCanContinue(true);
      form.setFieldsValue({
        prequalApprovalMonthlyPayment: values.prequalApprovalMonthlyPayment.toFixed(2),
      });
    }
  }, [form, prequal]);

  const header = {
    title: 'Financing Details',
    subtitle: 'What is your desired monthly payment?',
    content: 'You can always change this later.',
  };

  return (
    <PreQualFunnelLayout>
      <SectionHeader header={header} />
      <Form
        {...formItemLayout}
        form={form}
        initialValues={prequal}
        onFinish={updateValues}
        onValuesChange={checkMonthlyPayment}
      >
        <MoneyInput
          labelText="Desired monthly payment"
          name="prequalApprovalMonthlyPayment"
          validator={validator}
          validateTrigger="onSubmit"
        />
        <NavigationRow canContinue={canContinue} form={form} />
      </Form>
    </PreQualFunnelLayout>
  );
});

export default BoatPriceStep;
