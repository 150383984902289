import { Loan } from '../schema';
import { LoanStore } from '../stores/store';

export type LenderFinancingPreferenceContentKey =
  | 'highestLoanAmountWithLowDownPayment'
  | 'highLoanAmountWithLowDownPayment'
  | 'lowLoanAmountWithLowDownPayment'
  | 'midRangeLoanAmountWithLowDownPayment'
  | 'nearHighestLoanAmountWithLowDownPayment';

export type LenderFinancingPreference = {
  hasAnyPreferences: boolean;
  isHighestLoanAmountWithLowDownPayment: boolean;
  isHighLoanAmountWithLowDownPayment: boolean;
  isLowLoanAmountWithLowDownPayment: boolean;
  isMidRangeLoanAmountWithLowDownPayment: boolean;
  isNearHighestLoanAmountWithLowDownPayment: boolean;
};

export const checkLenderPreferences = (
  loanStore: LoanStore,
  loan: Loan
): LenderFinancingPreference => {
  if (!loanStore.financedAmount || !loan?.finance?.downPayment || !loan?.finance?.purchasePrice) {
    return {} as LenderFinancingPreference;
  }

  const loanAmount = loanStore.financedAmount;

  const isAboveHighAndBelowHighestLoanAmount = loanAmount >= 200000 && loanAmount <= 249999;
  const isHighestPossibleLoanAmount = loanAmount >= 250000;
  const isHighLoanAmount = loanAmount >= 150000 && loanAmount <= 199999;
  const isLowLoanAmount = loanAmount >= 10000 && loanAmount <= 99999;
  const isMidRangeLoanAmount = loanAmount >= 100000 && loanAmount <= 149999;

  const downPaymentPercent = Math.round(
    (loan.finance.downPayment / loan.finance.purchasePrice) * 100
  );

  const isHighestLoanAmountWithLowDownPayment =
    isHighestPossibleLoanAmount && downPaymentPercent < 20;
  const isHighLoanAmountWithLowDownPayment = isHighLoanAmount && downPaymentPercent < 20;
  const isLowLoanAmountWithLowDownPayment = isLowLoanAmount && downPaymentPercent < 10;
  const isMidRangeLoanAmountWithLowDownPayment = isMidRangeLoanAmount && downPaymentPercent < 15;
  const isNearHighestLoanAmountWithLowDownPayment =
    isAboveHighAndBelowHighestLoanAmount && downPaymentPercent < 20;

  return {
    hasAnyPreferences:
      isHighestLoanAmountWithLowDownPayment ||
      isHighLoanAmountWithLowDownPayment ||
      isLowLoanAmountWithLowDownPayment ||
      isMidRangeLoanAmountWithLowDownPayment ||
      isNearHighestLoanAmountWithLowDownPayment,
    isHighestLoanAmountWithLowDownPayment,
    isHighLoanAmountWithLowDownPayment,
    isLowLoanAmountWithLowDownPayment,
    isMidRangeLoanAmountWithLowDownPayment,
    isNearHighestLoanAmountWithLowDownPayment,
  };
};
