import React, { forwardRef } from 'react';
import Cleave from 'cleave.js/react';

const MoneyInput = forwardRef<HTMLInputElement, any>((props, ref) => (
  <Cleave
    ref={ref}
    options={{
      prefix: '$ ',
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      numeral: true,
      numericOnly: true,
      numeralPositiveOnly: true,
      numeralDecimalScale: 2,
    }}
    className="ant-input"
    onBlur={(event) => {
      // work around no event fired but prefix is added and placeholder label doesn't know about it
      props?.onChange(event);
    }}
    inputMode="decimal"
    {...props}
  />
));

export default MoneyInput;
