import React, { useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import styles from './LoanFinanceModal.module.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LenderFinancingPreferenceContentKey } from '../../utils/loan';

const ModalContentWrapper = ({ children }: { children: React.ReactChild }) => (
  <ul className={styles.loanFinanceModalContentWrapper}>{children}</ul>
);

const LOAN_FINANCE_CONTENT: {
  [K in LenderFinancingPreferenceContentKey]: React.ReactNode;
} = {
  highestLoanAmountWithLowDownPayment: (
    <ModalContentWrapper>
      <>
        <li>
          To qualify for our advertised rate and maximum term, a minimum down payment of 20% to 30%
          of the purchase price is recommended.
        </li>
        <li>Additional rate and term options are available.</li>
      </>
    </ModalContentWrapper>
  ),
  nearHighestLoanAmountWithLowDownPayment: (
    <ModalContentWrapper>
      <>
        <li>
          For credit scores between 700 and 749, a minimum down payment of 20% of the purchase price
          is recommended for our advertised rate and maximum term.
        </li>
        <li>
          For well-qualified applicants with a credit score of 750 or higher may be eligible for a
          minimum down payment as low as 15% of the purchase price.
        </li>
        <li>Additional rate and term options are available.</li>
      </>
    </ModalContentWrapper>
  ),
  highLoanAmountWithLowDownPayment: (
    <ModalContentWrapper>
      <>
        <li>
          For credit scores between 700 and 749, a minimum down payment of 20% of the purchase price
          is recommended for our advertised rate and maximum term.
        </li>
        <li>
          For well-qualified applicants with a credit score of 750 or higher may be eligible for a
          minimum down payment as low as 10% of the purchase price.
        </li>
        <li>Additional rate and term options are available.</li>
      </>
    </ModalContentWrapper>
  ),
  lowLoanAmountWithLowDownPayment: (
    <ModalContentWrapper>
      <>
        <li>
          For credit scores between 600 and 679, a minimum down payment of 10% of the purchase price
          is required.
        </li>
        <li>For credit scores between 680 and 749, a 10% down payment is recommended.</li>
        <li>
          For well-qualified applicants with a credit score of 750 or higher, may be eligible for a
          minimum down payment less than 10% of the purchase price.
        </li>
        <li>Additional rate and term options are available.</li>
      </>
    </ModalContentWrapper>
  ),
  midRangeLoanAmountWithLowDownPayment: (
    <ModalContentWrapper>
      <>
        <li>
          For credit scores between 700 and 749, a minimum down payment of 15% to 20% of the
          purchase price is recommended for our advertised rate and maximum term.
        </li>
        <li>
          For well-qualified applicants with a credit score of 750 or higher, may be eligible for a
          minimum down payment as low as 10% of the purchase price.
        </li>
        <li>Additional rate and term options are available.</li>
      </>
    </ModalContentWrapper>
  ),
};

export interface LoanFinanceModalProps {
  contentKey: LenderFinancingPreferenceContentKey | null;
  isOpen: boolean;
  onNext: () => void;
  onUpdateDownPayment: () => void;
  title?: string;
}

export const LoanFinanceModal = ({
  contentKey,
  isOpen,
  onNext,
  onUpdateDownPayment,
  title = 'Down Payment',
}: LoanFinanceModalProps) => {
  const previousFocusRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      previousFocusRef.current = document.activeElement as HTMLElement;
    }
  }, [isOpen]);

  const handleAfterClose = () => {
    if (previousFocusRef.current) {
      previousFocusRef.current.focus();
    }
  };

  if (!contentKey) {
    return null;
  }

  return (
    <Modal
      afterClose={handleAfterClose}
      aria-labelledby="finance-modal-title"
      aria-modal="true"
      destroyOnClose
      keyboard
      maskClosable={false}
      onCancel={onUpdateDownPayment}
      onOk={onNext}
      visible={isOpen}
      title={
        <div className={styles.loanFinanceModalTitleContainer}>
          <ExclamationCircleOutlined className={styles.loanFinanceModalIcon} />
          <h3 id="finance-modal-title" className={styles.loanFinanceModalTitleText}>
            {title}
          </h3>
        </div>
      }
      footer={[
        <Button
          key="updateDownPayment"
          onClick={onUpdateDownPayment}
          aria-label="Update Down Payment"
        >
          Update Down Payment
        </Button>,
        <Button
          className={styles.loanFinanceModalNextCTA}
          key="submit"
          onClick={onNext}
          type="primary"
          aria-label="Proceed to Next Step"
        >
          Next
        </Button>,
      ]}
    >
      {LOAN_FINANCE_CONTENT[contentKey]}
    </Modal>
  );
};
